import React from "react"

import Radium, { StyleRoot } from "radium";
import * as s from "../styles/website";

const NavigationMenu = () => {
    return (
        <div style={s.menuWrapper}>
            <ul style={s.menuUl}>
                <li style={s.menuLi}><a style={s.menuA} href="/" title="Go to Homepage">🏠</a></li>
                <li style={s.menuLi}><a style={s.menuA} href="https://linktr.ee/kevincittadini" target="_blank" title="Go to Linktree">🌲</a></li>
            </ul>
        </div>
    );
}

export default Radium(NavigationMenu);
