import React from "react";
import Radium, { StyleRoot } from "radium";
import * as s from "../styles/website";
import {StaticImage} from "gatsby-plugin-image";

const KevinsPicture = () => {
  return (
      <div style={s.kevinPictureWrapper}>
        <a href="/"><StaticImage style={s.kevinPicture} src="../images/kev.png" alt="Kevin Cittadini" /></a>
      </div>
  )
};

export default Radium(KevinsPicture);
