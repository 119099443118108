export const wrapper = {
    display: "block",
    height: "100%",
};

export const header = {
    display: 'block',
    width: "100%",
    height: "100%",
    background: 'rgb(35, 38, 48)',
    "@media (min-width: 850px)": {
        width: '30%',
        overflow: "hidden",
        position: "fixed",
    },
};

export const kevinPictureWrapper = {
    overflow: 'hidden',
    border: '10px solid #313441',
    borderRadius: '50%',
    width: '50%',
    marginTop: '25px',
    maxWidth: '250px',
    margin: '0 auto',
}

export const kevinPicture = {
    display: "block",
    width: '100%',
    textAlign: 'center',
}

export const headerColumnBlogPost = {
    float: 'left',
    width: '50%',
    "@media (min-width: 849px)": {
        width: '100%',
    },
}

export const h1 = {
    textAlign: 'center',
    fontSize: '16px',
    "@media (min-width: 400px) and (max-width: 499px)": {
        fontSize: '22px',
    },
    "@media (min-width: 500px) and (max-width: 849px)": {
        fontSize: '30px',
    },
    "@media (min-width: 850px) and (max-width: 1099px)": {
        fontSize: '16px',
    },
    "@media (min-width: 1100px) and (max-width: 1509px)": {
        fontSize: '25px',
    },
    "@media (min-width: 1510px) and (max-width: 1799px)": {
        fontSize: '30px',
    },
    "@media (min-width: 1800px)": {
        fontSize: '36px',
    },
}

export const presentationLongMediaQuery = {
    display: 'none',
    "@media (min-width: 850px)": {
        display: 'block',
    },
}

export const presentationShortMediaQuery = {
    display: 'block',
    "@media (min-width: 850px)": {
        display: 'none',
    },
}

export const copyrightDesktopMediaQuery = {
    display: 'none',
    "@media (min-width: 850px)": {
        display: 'block',
    },
}

export const copyrightMobileMediaQuery = {
    display: 'block',
    marginTop: '200px',
    "@media (min-width: 850px)": {
        display: 'none',
    },
}

export const code = {
    fontWeight: '100',
}

export const main = {
    display: 'block',
    width: "100%",
    height: "100%",
    "@media (min-width: 850px)": {
        width: '70%',
        margin: "0 0 0 auto",
    },
};

export const innerPadding = {
    padding: "20px",
};

export const menuWrapper = {
    textAlign: 'center',
    "@media (min-width: 850px)": {
        marginTop: '20px',
        paddingTop: '25px',
        borderTop: '1px solid #888',
    },
};

export const menuUl = {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    overflow: 'hidden',
    display: 'inline',
};

export const menuLi = {
    display: 'inline-block',
};

export const menuA = {
    fontSize: '50px',
    padding: '15px 35px',
    borderRadius: '30px'
};

export const copyright = {
    fontSize: '11px',
    color: '#999',
    textAlign: 'center',
    marginTop: '30px',
};

export const homepageArticleWrapper = {
    background: 'rgb(35, 38, 48)',
    padding: '5px 20px 20px',
    marginBottom: '20px',
};

export const homepageArticleDateTime = {
    margin: 0,
    padding: '15px 0 0',
    fontSize: '13px',
    color: '#aaa',
}

export const homepageArticleTitle = {
    fontSize: '25px',
    fontWeight: 'bold',
    margin: '0 0 15px'
}

export const homepageArticleDescription = {
    fontSize: '16px',
    margin: 0
}
