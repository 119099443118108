import React from "react";

const GoogleAnalytics = () => {
    if (process.env === 'dev') {
        return (<></>);
    }

    return (
        <>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-P2LFM7VBLP"></script>
            <script
                dangerouslySetInnerHTML={{
                    __html: `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-P2LFM7VBLP');`
                }}
            >
            </script>
        </>
    )
}

export default GoogleAnalytics;