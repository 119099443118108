import React from "react"

import { Helmet } from "react-helmet"
import SiteConfig from "../../site-config";
import SocialCardImage from "../images/social-card.png";

const Head = (props) => {
    const pageTitle = (props.title ? props.title + " — " : "") + SiteConfig.title;
    const pageDescription = props.description || SiteConfig.description;
    return (
        <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
            <meta name="theme-color" content="#232630" />
            <link rel="canonical" href={props.siteUrl || SiteConfig.siteUrl} />
            <meta property="og:image" content={SiteConfig.siteUrl + SocialCardImage} />
            <meta property="og:title" content={pageTitle} />
            <meta property="og:description" content={pageDescription} />
            <meta name="twitter:image" content={SiteConfig.siteUrl + SocialCardImage} />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
}

export default Head;