import React from "react"

import * as s from "../styles/website";

const Copyright = () => {
    const year = new Date().getFullYear();
    return (
        <p style={s.copyright}>
            Copyright &copy; {year} Kevin Cittadini — Privacy and Cookies
        </p>
    );
}

export default Copyright;
